import LazyComponent from 'components/LazyComponent';
import {lazy} from 'react';

const supportRoutes = [
	{
		component: LazyComponent(lazy(() => import('./Support.js'))),
		path: '/support',
	},
];

export default supportRoutes;
