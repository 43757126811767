import LazyComponent from 'components/LazyComponent';
import {lazy} from 'react';

const importRoutes = [
	{
		component: LazyComponent(lazy(() => import('./GeneralDataImport'))),
		path: '/general-data-import',
	},
];

export default importRoutes;
